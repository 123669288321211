<template>
  <div class="my-flex-container-column">
    <system-journal-filter :filter-model="filterModel"  @clear-filters="clearFilters" />
    <div class="my-flex-container-column">
    <pagination
      class="text-center"
      :total-count="totalCount"
      :page-size.sync="pageSize"
      :current-page.sync="pageNumber"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-table style="width: 100%;"
              v-loading="loading"
              :data="items"
              :height="tableHeight"
              :default-sort = "{prop: 'logged', order: 'descending'}"
              @sort-change="handleSortChange">
      <el-table-column prop="logged"
                       label="Дата"
                       sortable="custom"
                       min-width="7"
                       :formatter="dateTimeRenderer" />
      <el-table-column prop="userName"
                       label="Логин"
                       sortable="custom"
                       min-width="7" />
      <el-table-column prop="ip"
                       label="ip адрес"
                       sortable="custom"
                       min-width="5" />
      <el-table-column prop="level"
                       label="Уровень"
                       sortable="custom"
                       min-width="5" />
      <el-table-column prop="message"
                       label="Информация"
                       min-width="20" >
        <template slot-scope="scope" v-if="scope.row.message">
          <div class="exception-container">
            {{ scope.row.message.length > 114 ? scope.row.message.slice(0,115) + '...' : scope.row.message }}
            <el-button v-if="scope.row.message.length > 114" type="text" icon="el-icon-d-arrow-right" class="exception__button"
              @click="open(scope.row.message)"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="exception"
                       label="Ошибка"
                       min-width="20">
        <template slot-scope="scope" v-if="scope.row.exception">
          <div class="exception-container">
            {{ scope.row.exception.length > 114 ? scope.row.exception.slice(0,115) + '...' : scope.row.exception }}
            <el-button v-if="scope.row.exception.length > 114" type="text" icon="el-icon-d-arrow-right" class="exception__button"
              @click="open(scope.row.exception)"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="category"
                       label="Категория"
                       min-width="5" />
    </el-table>
    </div>  
  </div>
</template>

<script>
import formatters from '@/mixins/formatters';
import tableResize from '@/mixins/tableResize.js';
import pagination from '@/components/Ui/Pagination';
import sysJournalApi from '@/api/administration/systemJournal';
import SystemJournalFilter from './SystemJournalFilter.vue';

export default {
  name: 'EntityJournal',
  mixins: [formatters, tableResize],
  components: { pagination, SystemJournalFilter },
  data() {
    return {
      loading: false,
      items: [],
      pageNumber: 1,
      pageSize: 20,
      totalCount: 0,
      sortField: 'logged',
      sortDesc: true,
      filterModel: {
        loggedDateTo: '',
        loggedDateFrom: '',
        userNameFilter: '',
        levelFilter: ['Error'],
        categoryFilter: []
      },
    };
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getEntityJournal();
    this.tableHeight = this.getTableHeight();
  },
  watch: {
    filterModel: {
      handler() {
        this.getEntityJournal();
      },
      deep: true
    },
  },
  methods: {
    async getEntityJournal() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        loggedDateTo: this.filterModel.loggedDateTo,
        loggedDateFrom: this.filterModel.loggedDateFrom,
        userNameFilter: this.filterModel.userNameFilter,
        levelFilter: this.filterModel.levelFilter,
        categoryFilter: this.filterModel.categoryFilter
      };
      const res = await sysJournalApi.getEntityJournal(params);
      if (res.data) {
        this.items = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getEntityJournal();
    },
    async handleCurrentChange() {
      await this.getEntityJournal();
    },
    async handleSortChange(event) {
      this.sortField = event.prop;
      this.sortDesc = event.order === 'descending';
      await this.getEntityJournal();
    },
    open(text) {
        this.$alert(text, '', {
          confirmButtonText: 'OK',
          customClass: 'exception__modal'
        });
    },
    clearFilters() {
      this.filterModel = {
        loggedDateTo: '',
        loggedDateFrom: '',
        userNameFilter: '',
        levelFilter: ['Error'],
        categoryFilter: []
      };
    }
  }
};
</script>

<style scoped>
  /*TODO: модалка?*/
  .exception-container {
    overflow: hidden;
    width: 100%;
    max-height: 90px;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
  }
</style>
